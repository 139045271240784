<template>
  <el-container direction="vertical" style="height: 100%" class="container">
    <div v-if="showCode" style="width: 100%;">
      <h2 style="margin-bottom: 50px;">社区二维码生成</h2>
      <div v-show="xianshia">
        <div class="zitia" style="margin-top:50px;">
          {{ canshu.pro_address }}{{ canshu.city_address }}{{ canshu.area_address }}「{{ canshu.community_name }}『{{
            check_name }}』二维码
        </div>
        <!-- <div class="ma" style="margin-top: 80px;">
          <img v-if="src" :src="src" alt="二维码" />
        </div> -->
        <div class="ma" style="margin-top: 80px;">
          <el-image style="width: 220px;margin-bottom: 12px; height: 220px" :src="qrCodeSrc"
            :preview-src-list="[qrCodeSrc]">
          </el-image>
        </div>
        <div style="margin-top: 100px;">
          <el-button style="width: 200px;" type="warning" @click="downLoad()">下载</el-button>
        </div>
        <!-- <hr style="width: 90%;margin-top: 30px;" /> -->
      </div>
    </div>
    <div v-if="!showCode" style="width: 100%;">
      <h2 style="margin-bottom: 30px;">「入园、入校」社区码生成</h2>
      <el-form :model="canshu" :rules="rules" ref="canshu" class="demo-form-inline" label-width="100px" size="medium">
        <el-form-item label="省" style="width: 90%;" prop="pro_address">
          <el-select v-model="canshu.pro_address" placeholder="陕西省" style="width: 100%;" @change="dataChange(2)">
            <el-option v-for="item in proNameArr" :key="item.province" :label="item.province" :value="item.province">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" style="width: 90%;" prop="city_address">
          <el-select v-model="canshu.city_address" placeholder="西安市" style="width: 100%;" @change="dataChange(3)">
            <el-option v-for="item in cityNameArr" :key="item.city" :label="item.city" :value="item.city">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区" style="width: 90%;" prop="area_address">
          <el-select v-model="canshu.area_address" placeholder="请选择地区" style="width: 100%;" @change="dataChange(4)">
            <el-option v-for="item in areaNameArr" :key="item.area" :label="item.area" :value="item.area">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查体类型" style="width: 90%;" prop="check_type">
          <el-select v-model="canshu.check_type" placeholder="请选择查体类型" style="width: 100%;" @change="checkTypeChange">
            <!-- <el-option label="入托" value="1"></el-option> -->
            <el-option label="入园" value="2"></el-option>
            <el-option label="入校" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查体点类型" style="width: 90%;" prop="points_type">
          <el-select v-model="canshu.points_type" placeholder="请选择查体点类型" style="width: 100%;">
            <el-option label="维稳" value="1"></el-option>
            <el-option label="提升" value="2"></el-option>
            <el-option label="新增" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社区名称" style="width: 90%;" prop="community_name">
          <el-select v-model="canshu.community_name" placeholder="请选择社区名称" style="width: 100%;" @change="communityChange">
            <el-option v-for="item in communityNameArr" :key="item.community_name" :label="item.community_name"
              :value="item.community_name">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item v-if="canshu.check_type == 2 || canshu.check_type == 3" label="学校名称"
          style="width: 90%;" prop="school_name">
          <el-input v-model="canshu.school_name" placeholder="请输入学校名称"></el-input>
        </el-form-item> -->
        <el-form-item style="width: 90%;margin-top: 100px;">
          <el-button type="primary" @click="onSubmit('canshu')">生成社区二维码</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>
import QRCode from 'qrcode';
import { Download } from "./download";//文件下载
import {
  getTjQrcodeCommunity, getAreaData, getTjNames, baseToUrl
} from "../../api/javaApi";
export default {
  name: 'QRCodeGenerator',
  data() {
    return {
      qrCodeSrc: "",
      showCode: false,
      codeUrl: baseToUrl + 'tjQrcodeSchool?id=',
      proNameArr: [],
      cityNameArr: [],
      areaNameArr: [],
      download: new Download(),
      base64: '',
      src: '',  // 用于存储图片的Base64编码
      dayinanniu: true,
      uploadDisabled: false,
      communityNameArr: [],
      schoolNameArr: [],
      check_name: '',
      canshu: {
        check_type: '',
        content_type: '1',
        points_type: '',
        pro_address: '',
        city_address: '',
        area_address: '',
        community_name: '',
        school_name: ""
      },
      xianshia: false,
      rules: {
        pro_address: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        city_address: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        area_address: [
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        check_type: [
          { required: true, message: '请选择查体类型', trigger: 'change' }
        ],
        points_type: [
          { required: true, message: '请选择查体点类型', trigger: 'change' }
        ],
        community_name: [
          { required: true, message: '请选择社区名称', trigger: 'change' }
        ],
        // school_name: [
        //   { required: true, message: '请输入学校名称', trigger: 'blur' }
        // ],
        content_type: [
          { required: true, message: '请选择内容类型', trigger: 'change' }
        ],
      }
    };
  },
  created() {
    this.dataChange(1)
  },
  mounted() {

  },
  methods: {
    generateQRCode(text) {
      return new Promise((resolve, reject) => {
        let canvas = document.createElement("canvas")
        QRCode.toCanvas(canvas, text, { errorCorrectionLevel: 'H' })
          .then(() => {
            const img = canvas.toDataURL("image/png");
            resolve(img);
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    downLoad() {
      let downloadName = this.canshu.school_name ? this.canshu.area_address + this.canshu.community_name + this.canshu.school_name + ".png" : this.canshu.area_address + this.canshu.community_name + ".png";//文件名
      let imgData = this.qrCodeSrc;
      // this.download.downloadFile(downloadName, imgData);
      const a = document.createElement('a')
      a.href = imgData
      a.setAttribute("download", downloadName)
      a.click();
    },
    async dataChange(flag) {
      console.log(flag)
      let data = {
        flag: flag,
        province: this.canshu.pro_address,
        city: this.canshu.city_address,
        area: this.canshu.area_address,
      };
      getAreaData(data)
        .then((res) => {
          console.log(res)
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            if (flag == 1) {
              this.proNameArr = res.data.data
            } else if (flag == 2) {
              this.cityNameArr = res.data.data
            } else if (flag == 3) {
              this.areaNameArr = res.data.data
            } else {
              this.communityNameArr = res.data.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSchoolNames() {
      let data = {
        type: 2,
        province: this.canshu.pro_address,
        city: this.canshu.city_address,
        area: this.canshu.area_address,
        communityName: this.canshu.community_name,
      };
      getTjNames(data)
        .then((res) => {
          console.log(res)
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            this.schoolNameArr = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCode() {
      let data = {
        province: this.canshu.pro_address,
        city: this.canshu.city_address,
        area: this.canshu.area_address,
        check_type: this.canshu.check_type,
        address_type: this.canshu.points_type,
        community_name: this.canshu.community_name,
        // school_name: this.canshu.school_name,
        content_type: this.canshu.content_type
      };
      getTjQrcodeCommunity(data)
        .then(async (res) => {
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            let url = baseToUrl + "tjQrcodeSchool?id=" + res.data.data.id + "&contentType=" + res.data.data.contentType
            console.log(url)
            this.codeUrl = url
            await this.generateQRCode(url).then((imgSrc) => {
              this.qrCodeSrc = imgSrc
              this.showCode = true;
            })

            // this.base64 = res.data.data;
            // this.src = 'data:image/jpeg;base64,' + res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkTypeChange(res) {
      if (res == 1) {
        this.check_name = '入托'
      } else if (res == 2) {
        this.check_name = '入园'
      } else if (res == 3) {
        this.check_name = '入校'
      }
    },
    areaChange() {
      this.getCommunityNames()
    },
    communityChange() {
      if (!this.canshu.check_type) {
        return this.$notify.error({
          title: "警告",
          message: "请选择查体类型",
          type: "warning",
          duration: "2000",
        });
      }
      // if (this.canshu.check_type == 2 || this.canshu.check_type == 3) {
      //   this.getSchoolNames()
      // }
    },
    xiayibu() {
      if (this.canshu.area_address == "" || this.canshu.area_address == null)
        return this.$notify.error({
          title: "警告",
          message: "请选择地区!",
          type: "warning",
          duration: "2000",
        });
      if (this.canshu.address == "" || this.canshu.address == null)
        return this.$notify.error({
          title: "警告",
          message: "请输入测评地点!",
          type: "warning",
          duration: "2000",
        });

      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
      })
      setTimeout(e => {
        this.uploadDisabled = false;
      }, 1000)

    },
    async onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.xianshia = true
          this.getCode();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
/* 设置基础样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* 针对桌面和平板设备的样式 */
.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  text-align: center;
  height: 100vh;
  font-size: 1vw;
}

/* 下面的CSS是针对移动端设备的样式 */
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    /* 移动端的最大宽度设置为100% */
    padding: 5px;
    /* 减小了padding，以适应较小的屏幕 */
  }
}

img {
  max-width: 300px;
  margin-top: 10px;
}

.content {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;

  .pics {
    position: absolute;
    top: 100px;
    right: 50px;
    width: 214px;
    height: auto;

  }

  .zitia {
    font-weight: 900;
  }

  .btn {
    margin-bottom: 30px;
  }

  .ma {
    width: 100%;
    height: 200px;
    margin: 20px 0;
    text-align: center;

    .qrcode {
      display: inline-block;
      width: 200px;
      height: 200px;
      box-sizing: border-box;

      img {
        width: 200px;
        height: 200px;
        background-color: #fff;
        background-position: center;
        box-sizing: border-box;
      }
    }
  }
}

.heisa {
  width: 100%;
  height: 100%;

  .dayinmaa {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
  }
}

.shangchuan {
  .avatar-uploader {
    /deep/ .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    /deep/ .el-upload:hover {
      border-color: #409EFF;
    }
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

/deep/ .disabled .el-upload--picture-card {
  display: none !important;
}
</style>
